<template>
  <div>
    <v-sheet class="pa-8">
      <p class="text-h5">
        Create POS
      </p>
      <FormInput
        :loading="loading"
        :warehouse="warehouse"
        :status-list="statusList"
        @onSubmit="onSubmit"
        @onCancel="$router.go(-1)"></FormInput>
    </v-sheet>
  </div>
</template>

<script>
import PosManagementProvider from '@/resources/PosManagementProvider'
import { mapGetters } from 'vuex'
import FormInput from '../components/form-input.vue'

export default {
  components: {
    FormInput
  },
  data () {
    return {
      posManagementProvider: new PosManagementProvider(),
      statusList: [
        'active',
        'inactive',
        'pm',
        'ma',
        'discharge'
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouse () {
      return this.mapWarehouse.filter((wh) => wh.code !== 'main')
    }
  },
  methods: {
    async onSubmit (val) {
      try {
        this.loading = true
        await this.posManagementProvider.createPos(val)
        this.$router.push({ name: 'PosManagement', query: { warehouse: val.warehouse.id } })
      } catch (error) {
        console.error('createPos', error)
      }
      this.loading = false
    }
  }
}
</script>
