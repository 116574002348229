import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PosManagementProvider extends HttpRequest {
  createPos (payload) {
    this.setHeader(getAuthToken())
    return this.post('/pos-management', payload)
  }

  paginate (query) {
    this.setHeader(getAuthToken())
    return this.get('/pos-management', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/pos-management/${id}`)
  }

  updateById (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/pos-management/${id}`, payload)
  }

  addNote (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/pos-management/${id}/add-note`, payload)
  }

  deleteById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/pos-management/${id}`)
  }

  getPosDeviceByWarehouseId (payload) {
    this.setHeader(getAuthToken())
    return this.get('/pos-management/warehouse', payload)
  }
}

export default PosManagementProvider
